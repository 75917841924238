import React, { useState } from "react";
import { Container, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

// Component for the email lookup
function EmailLookup() {
  const [creatorIDs, setCreatorIDs] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      // Split the creator IDs by line instead of commas
      const ids = creatorIDs.split("\n").map(id => id.trim()).filter(id => id);
      const response = await axios.post("http://47.251.67.81:8000/api/emails", { ids });
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching emails", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + tableData.map(row => `${row.creator_id},${row.email}`).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "creator_emails.csv");
    document.body.appendChild(link); 
    link.click();
  };

  return (
    <Container>
      <TextField
        fullWidth
        variant="outlined"
        label="Enter Creator IDs (one per line)"
        value={creatorIDs}
        onChange={(e) => setCreatorIDs(e.target.value)}
        margin="normal"
        multiline
        rows={10}
      />
      <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading} style={{ marginTop: "20px" }}>
        {loading ? "Searching..." : "Search Emails"}
      </Button>

      {tableData.length > 0 && (
        <>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Creator ID</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.creator_id}>
                    <TableCell>{row.creator_id}</TableCell>
                    <TableCell>{row.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" color="secondary" onClick={handleExport} style={{ marginTop: "20px" }}>
            Export to CSV
          </Button>
        </>
      )}
    </Container>
  );
}

function Home() {
  return (
    <Container>
      <h1>LinkNLatch Toolbox</h1>
      <p>
        1. <Link to="/get_email">Email Finder</Link>: find creator emails given a list of creator IDs.
      </p>
    </Container>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route for /get_email */}
        <Route path="/get_email" element={<EmailLookup />} />
        
        {/* Define the root route */}
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
